.container{
    background: url('./BarbieR&D.jpg') no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    border-radius: 25px;
  }

  .header{
    background-color: #f021a3;
    padding: 20px;
    font-family: 'Inclusive Sans', sans-serif;
  }
.parent-box{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center; 
}

.box{
    background-color: white;
    border-radius: 25px;
    height:80%;
    border: 10px solid hotpink;
    box-shadow: 8px 8px 16px #FFD1DC;

    @media (min-width: 769px) {
        width: 415px;
    }
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

.starring{
    font-weight: 900;
    font-size: 40px;
}
.img1{
    transform: rotate(-15deg);
    position: absolute;
    width: 500px;
    top: 100px;
}

.cast{
    height: 80%;
    text-align: center;
    color: #f021a3;
}

.marquee{
    text-align: center;
    height: 70%;
    font-size:32px;
}

.nav {
    list-style-type: none;
    font-size: 20px;

}

a {
    text-decoration: none;
    color: white;
}


.about {
    font: white;
    border: 12px solid #FF93AC;
    box-shadow: 8px 8px 16px #FFD1DC;
    padding: 20px;
}


.imgs{
    width: 300px;
}


.grid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  
  .grid-item {
    flex: 0 0 calc(33.33% - 10px); /* 3 columns, minus the desired gap between items */
    margin: 5px; /* Adjust this for the desired gap between items */
    text-align: center;
    border: 5px solid white;
    border-radius: 24px;
    background-color: white;
  }